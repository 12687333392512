
/**
 * @name: fullReduce
 * @author: Gzm
 * @date: 2023-07-21 09:57
 * @description：营销活动-满减活动
 * @update: 2023-07-21 09:57
 */
import {Vue, Component} from "vue-property-decorator"
import {INotice, INoticeParam} from "@/apis/notice/types"
import {ICrudOption} from "@/types/m-ui-crud"
import {deepCopy} from "@/utils/common";
import {
  fullReduceCreateApi,
  fullReduceGoodsSelectedApi,
  fullReduceModifyApi,
  fullReduceQueryApi,
  fullReduceRemoveApi, goodsBatchAddApi, goodsWarehouseApi
} from "@/apis/marketing";
import {IFullReduce} from "@/apis/marketing/types";

@Component({})
export default class FullReducePage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: INotice[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: INoticeParam = {
    page: 1,
    limit: 10
  }
  // 会员库
  transferData = [{label: 'sdas', key: '1'}, {label: 'sd2213as', key: '12'}]
  // 教练库
  ids = ['12']
  // titleList名称列表
  titleList = ['商品库', '已选商品']
  goodsWarehouse = [] as any
  fullReduceSelected = [] as any
  fullReduceSelectedIds = [] as any
  goodsDialog = false
  addDialog = false
  fullReduceId = ''
  // 表单数据
  modelForm: Partial<any> = {}
  // 配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    dialogWidth: '800px',
    column: [
      {
        label: "活动",
        prop: "activityName",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true,
        hide: true,
        search: true,
        placeholder: '输入ID/名称模糊搜索'
      },
      {
        label: "活动ID",
        prop: "id",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true
      },
      {
        label: "活动名称",
        prop: "activityName",
        editSlot: true,
        addSlot: true,
        overHidden: true,
        slot: true,
        align: "center"
      },
      {
        label: "活动商品数量",
        prop: "goodQty",
        align: "center",
        editHide: true,
        addHide: true,
        slot: true
      },
      {
        label: "状态",
        prop: "status",
        slot: true,
        addHide: true,
        editHide: true,
        width: 150,
        align: 'center',
        search: true,
        type: "switch",
        dicData: [
          {
            label: "启用",
            value: 1
          },
          {
            label: "禁用",
            value: 2
          }
        ]
      },
      {
        label: "最近一次经办人",
        prop: "name",
        align: "center",
        editHide: true,
        addHide: true,
        search: true
      },
      {
        label: "更新时间",
        prop: "editTime",
        align: "center",
        editHide: true,
        addHide: true,
        search: true,
        type: "daterange"
      },
    ]
  }

  getList() {
    this.tableLoading = true
    const form: IFullReduce = deepCopy(this.queryForm)
    if (form.editTime && form.editTime.length) {
      form.startTime = form.editTime[0]
      form.endTime = form.editTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.editTime
    fullReduceQueryApi(form).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total;
      this.tableLoading = false
    })
  }


  rowDel(form: INotice, index: number) {
    fullReduceRemoveApi(form.id).then(e => {
      this.$message.success("操作成功!")
      this.getList()
    })
  }

  openAdd() {
    this.addDialog = true
    this.modelForm = {}
  }

  openDetail(row: any) {
    this.goodsDialog = true
    this.fullReduceId = row.id
    this.goodsWarehouse = []
    this.fullReduceSelected = []
    this.fullReduceSelectedIds = []
    fullReduceGoodsSelectedApi(row.id).then(e => {
      this.fullReduceSelected = e.map((item: any) => {
        this.fullReduceSelectedIds.push(item.goodsId)
        return {
          label: item.goodsName,
          key: item.goodsId
        }
      })
      goodsWarehouseApi(row.id).then(e => {
        this.goodsWarehouse = e.map((item: any) => {
          console.log(item)
          return {
            label: item.goodsName,
            key: item.goodsId
          }
        })
        this.goodsWarehouse = [...this.goodsWarehouse, ...this.fullReduceSelected]
      })
    })
  }

  statusChange(id: string, isShow: number) {
    fullReduceModifyApi({id, status: isShow}).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }


  handleRepealAffirm() {
    goodsBatchAddApi({
      fullReduceId: this.fullReduceId,
      ids: this.fullReduceSelectedIds
    }).then(e => {
      this.$message.success('操作成功！')
      this.goodsDialog = false
      this.getList()
    })
  }

  handleAddAffirm() {
    if (!/^[1-9]{1}[0-9]{0,5}$/.test(this.modelForm.price)) {
      return this.$message.error('应输入大于0小于999999的正整数')
    }
    if (!/^[1-9]{1}[0-9]{0,5}$/.test(this.modelForm.totalPrice)) {
      return this.$message.error('应输入大于0小于999999的正整数')
    }
    if (parseInt(this.modelForm.price) >= parseInt(this.modelForm.totalPrice)) {
      return this.$message.error('减的价格不能大于等于商品价格')
    }
    fullReduceCreateApi(this.modelForm).then(e => {
      if (e) {
        this.$message.success('新增成功！')
        this.addDialog = false
        this.getList()
      }
    })
  }

  created() {
    this.getList()
  }
}
