/**
 * @name: index
 * @author: Gzm
 * @date: 2023-07-21 10:18
 * @description：index
 * @update: 2023-07-21 10:18
 */
import {get, postJ} from "@/request";
import {IFullReduce, IFullReduceCreate} from "@/apis/marketing/types";
import {IPurchaseOrderQuery} from "@/apis/purchase/types";

/**
 * 营销活动-满减活动-列表查询
 * @param param
 */
export const fullReduceQueryApi = (param: IFullReduce) => get("/mall/fullReduce/query", param)

/**
 * 营销活动-满减活动-创建
 * @param param
 */
export const fullReduceCreateApi = (param: IFullReduceCreate) => postJ("/mall/fullReduce/create", param)

/**
 * 营销活动-满减活动-修改
 * @param param
 */
export const fullReduceModifyApi = (param: IFullReduceCreate) => postJ("/mall/fullReduce/modify", param)

/**
 * 营销活动-满减活动-删除
 * @param id
 */
export const fullReduceRemoveApi = (id: string) => get("/mall/fullReduce/remove/" + id)

/**
 * 营销活动-满减活动-商品库 未加到特价和满减的商品
 * @param param
 */
export const goodsWarehouseApi = (id:string) => get("/mall/specialOffer/goods/warehouse",{id})

/**
 * 营销活动-满减活动-商品库 已选择满减商品
 * @param param
 */
export const fullReduceGoodsSelectedApi = (id:string) => get("/mall/fullReduce/goods/selected",{id})

/**
 * 营销活动-满减活动-批量新增或减少
 * @param param
 */
export const goodsBatchAddApi = (param: any) => postJ("/mall/fullReduce/goods/batchAdd", param)

/**
 * 营销活动-人气推荐-删除
 * @param id
 */
export const popularityGoodsRemoveApi = (ids: string[]) => postJ("/mall/popularityGoods/batch/remove" ,{ids})

/**
 * 营销活动-人气推荐商品仓库
 * @param param
 */
export const popularityGoodsApi = () => get("/mall/popularityGoods/warehouse")

/**
 * 营销活动-人气推荐商品已选择
 * @param param
 */
export const popularityGoodsSelectedApi = () => get("/mall/popularityGoods/selected")

/**
 * 营销活动-批量新增人气推荐商品
 * @param param
 */
export const popularityGoodsAddApi = (param: any) => postJ("/mall/popularityGoods/batchAddition", param)


/**
 * 营销活动-人气推荐-列表查询
 * @param param
 */
export const popularityGoodsQueryApi = (param: any) => get("/mall/popularityGoods/query", param)

/**
 * 营销活动-人气推荐-列表查询导出
 * @param param
 */
export const popularityGoodsExportApi = (param: any) => postJ("/mall/popularityGoods/export",  param, 'blob')

/**
 * 营销活动-特价活动-列表查询
 * @param param
 */
export const specialOfferGoodsQueryApi = (param: any) => get("/mall/specialOffer/query", param)

/**
 * 营销活动-特价活动-已选择特价商品
 * @param param
 */
export const specialOfferGoodsSelectedApi = () => get("/mall/specialOffer/goods/selected")

/**
 * 营销活动-特价活动-未加到特价和满减的商品
 * @param param
 */
export const specialOfferGoodsApi = () => get("/mall/specialOffer/goods/warehouse")

/**
 * 营销活动-特价活动-删除
 * @param id
 */
export const specialOfferBatchRemoveApi = (ids: string[]) => postJ("/mall/specialOffer/batch/remove" ,{ids})

/**
 * 营销活动-特价活动-批量添加特价商品
 * @param param
 */
export const specialOfferGoodsAddApi = (param: any) => postJ("/mall/specialOffer/goods/batchAdd", param)

/**
 * 营销活动-特价活动-修改
 * @param id
 */
export const specialOfferModifyApi = (param: any) => postJ("/mall/specialOffer/modify" ,param)

/**
 * 营销活动-特价活动-列表查询导出
 * @param param
 */
export const specialOfferGoodsExportApi = (param: any) => postJ("/mall/specialOffer/export",  param, 'blob')
